// components/HeroSection.js
import React from "react";
import myImage from "../assets/HeroLayer.png";
import { ReactComponent as WeTake } from "../assets/Wetake.svg";

import "../styles/Hero.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-text">
      <img src={myImage} alt="" className="mobile_img2" />

        <h1
          className="first animate__animated  animate__fadeInUp "
          data-wow-delay={"0.3s"}
        >
          Откройте мир маркетинга 
        </h1>
        <h1
          className=" animate__animated  animate__fadeInUp  second"
          data-wow-delay="0.4s"
        >
          с нашим курсОМ!
        </h1>
        
        <div className="mobile_hero_next">
          <div className="text_section">
            <p
              className=" animate__animated  animate__fadeInUp first"
              data-wow-delay="0.6s"
            >
              Этот курс рассчитан на начинающих экспертов и профессионалов,
              которые стремятся достичь максимальных результатов в своей
              деятельности. Благодаря уникальной стратегии и дополнительным
              инструментам станет понятно как можно легко создать Reels и
              получить поток клиентов и заявок!
            </p>

            <p
              className=" animate__animated  animate__fadeInUp second"
              data-wow-delay="0.6s"
            >
              <p>
                Научись эффективно использовать рилсы для привлечения новых
                клиентов и увеличения прибыли.
              </p>{" "}
              Успех уже близок – присоединяйся к курсу прямо сейчас!
            </p>
            <WeTake className="wetake" />
          </div>

          <img src={myImage} alt="" className="mobile_img" />
        </div>
      </div>
      <div className="cont">
        <img src={myImage} alt="" className="hero_img" />
      </div>
    </div>
  );
};

export default HeroSection;
