async function redirectToPaymentYookasa({ price }) {
  console.log("Received price:", price); // Додайте логування для перевірки вхідного значення

  if (!price || isNaN(parseFloat(price))) {
    console.error("Invalid price input:", price);
    return; // Перериваємо виконання функції, якщо ціна не є числом
  }

  const value = parseFloat(price);

  const paymentDetails = {
    amount: {
      value: value,
      currency: "RUB",
    },
    confirmation: {
      type: "redirect",
      return_url: "https://truelearn.academy/thanks",
    },
    description: "Оплата курса",
  };

  try {
    const response = await fetch(
      "https://server-gules-eta-30.vercel.app/api/createPayment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentDetails),
      }
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();

    if (data && data.confirmation && data.confirmation.confirmation_url) {
      window.location.href = data.confirmation.confirmation_url;
    } else {
      throw new Error("Payment URL not found");
    }
  } catch (error) {
    console.error("Payment error:", error);
  }
}

export default redirectToPaymentYookasa;
