import MainPage from "./Pages/MainPage";
import PurchasePage from "./Pages/PurchasePage.js";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/payment/:productId" element={<PurchasePage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    
    </div>
  );
}

export default App;