import React, { useRef, useState, useEffect } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/AnswerMob.css";
import Card from "./Card";

const AnswerMob3 = () => {
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [marginTop, setMarginTop] = useState('0'); 

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: (current) => setActiveSlide(current)
    };

    const updateMarginTop = (slideIndex) => {
        switch (slideIndex) {
            case 0:
                setMarginTop('0vw');
                break;
            case 1:
                setMarginTop('0vw');
                break;
            case 2:
                setMarginTop('0vw');
                break;
            default:
                setMarginTop('0');
        }
    };

    useEffect(() => {
        updateMarginTop(activeSlide); 
    }, [activeSlide]);

    const buttonStyle = (index) => ({
        backgroundColor: activeSlide === index ? '#9F3DFF' : '#00000000',
        borderColor: activeSlide === index ? '#00000' : '#5f5f5fa5',
    });

    const goToSlide = (slideIndex) => {
        sliderRef.current.slickGoTo(slideIndex);
        setActiveSlide(slideIndex); 
    };

    
    const lessons1 = [
        { title: "Урок 1", description: "Как работают алгоритмы в Reels?" },
        { title: "Урок 2", description: "Как работаю я?" },
        { title: "Урок 3", description: "Рост после рекламы?" },
        { title: "Урок 4", description: "Чистить аккаунт от ботов?" },
        { title: "Урок 5", description: "Портрет целевой аудитории" },
        { title: "Урок 6", description: 'Что такое "Лид-магнит"' },
        { title: "Урок 7", description: "Итог первого раздела" },
    ];
    const lessons2 = [
        { title: "Урок 1", description: "Личная страница Facebook" },
        { title: "Урок 2", description: "Бизнес-страница Facebook" },
        { title: "Урок 3", description: "Убираем блок ( для РФ ) " },
        { title: "Урок 4", description: "Связка страниц" },
        { title: "Урок 5", description: "Открываем AdsManager" },
        { title: "Урок 6", description: "Реклама на Direct" },
        { title: "Урок 7", description: "Реклама на просмотры" },
        { title: "Урок 8", description: "Музыка в рекламе" },
    ];
    const lessons3 = [
        { title: "Урок 1", description: "Регистрация в BotHelp" },
        { title: "Урок 2", description: "Настройка чат-бота" },
        { title: "Урок 3", description: "Наш первый чат-бот" },
        { title: "Урок 4", description: "Запускаем чат-бота" },
    ];


    return (
        <div className="carousellCont">
            <Slider ref={sliderRef} {...settings}>
                <div>
                    <Card
                        header="Основы моей стратегии"
                        lessons={lessons1}
                    />
                </div>
                <div>
                <Card
                        header="Основы моей стратегии"
                        lessons={lessons2}
                    />
                </div>
               
                <div>
                <Card
                        header="Основы моей стратегии"
                        lessons={lessons3}
                    />
                </div>
            </Slider>
            <div className='buttonContMob' style={{ marginTop: marginTop }}>
                <button className='mobButtonS' style={buttonStyle(0)} onClick={() => goToSlide(0)}></button>
                <button className='mobButtonS' style={buttonStyle(1)} onClick={() => goToSlide(1)}></button>
                <button className='mobButtonS' style={buttonStyle(2)} onClick={() => goToSlide(2)}></button>
            </div>
        </div>
    );
};

export default AnswerMob3;
