import React, { useState } from "react";
import { ReactComponent as UnionClosed } from "../assets/UnionClosed.svg";
import { ReactComponent as UnionOpen } from "../assets/UnionOpen.svg";
import { useNavigate } from "react-router-dom";

import "../styles/MyDropdown.css";

const MyDropdown = ({
  price,
  price2,
  question1,
  question2,
  answer,
  answer2,
  Pid,
  answer_mob
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/payment/${Pid}`);
  };

  return (
    <div
      className={`dropdown ${isOpen ? "open" : ""
        }  wow animate__animated animate__fadeIn`}
    >
      <div>
        <div className="dropdown-header">
          <div className="dropname" onClick={toggleDropdown}>
            <span className="dropdown-question1">{question1}</span>
            <span className="dropdown-question2">{question2}</span>
          </div>
          <div className="priceCont">
            <span className="priceP1">Цена: </span>

            {price2 && <span className="priceNCrossed">{price2} руб.</span>}

            <span className="priceN">{price}</span>
            <span className="priceP2"> руб.</span>

            <button className="purchase" onClick={handleButtonClick}>
              Приобрести
            </button>

            <span className="dropdown-icon">
              {isOpen ? (
                <UnionOpen className="dropdown-icon" onClick={toggleDropdown} />
              ) : (
                <UnionClosed
                  className="dropdown-icon"
                  onClick={toggleDropdown}
                />
              )}
            </span>
          </div>
          {/* //////////////////////////////////////////////// */}
          <div className="mobile">
            <button className="purchase-mobile" onClick={handleButtonClick}>
              Приобрести
            </button>
            <button
              className="purchase-mobile secondB"
              onClick={toggleDropdown}
            >
              <span className="dropdown-icon-mobile">
                Подробнее
                {isOpen ? (
                  <UnionOpen className="dropdown-icon-mobile" />
                ) : (
                  <UnionClosed className="dropdown-icon-mobile" />
                )}
              </span>
            </button>
          </div>
        </div>
        {/* //////////////////////////////////////////////// */}

        <div className="dropdown-body">
          <span className="answer1">
            {answer2}
            {answer}
          </span>

          <span className="answer2">
            {answer_mob}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MyDropdown;
