import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { ReactComponent as IN } from "../assets/INsvg.svg";
import { ReactComponent as TG } from "../assets/TGsvg.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import scrollToTop from "../helpers/scrollToTopHelper";
import "../styles/Footer.css";

const Footer = () => {

  return (
    <footer className="footer">
      <div className="downside wow animate__animated animate__fadeIn">
        <div className="social">
          <a
            href="https://t.me/Nh_exz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TG className="icon-tg icon-hover" />
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <IN className="icon icon-hover" />
          </a>
        </div>
        <span className="e-mail">support@truecurse.io</span>

        <div className="rights">
          <p>Гаврюшина Анна Алексеевна</p>
          <p>ИНН 772167856879</p>
          <p>Anna.h@vaksman.ru</p>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
