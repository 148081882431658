import React from "react";
import CryptoJS from "crypto-js";

function redirectToPaymentAaio(price) {
  const merchantId = process.env.REACT_APP_AAIO_MERCHANT_ID; // ID вашого магазину
  const secretKey = process.env.REACT_APP_AAIO_SECRET_KEY; // Ваш секретний ключ
  const currency = "RUB"; // Валюта замовлення
  const returnUrl = "https://yourwebsite.com/payment-success"; // URL для повернення після оплати
  const Key1 = process.env.REACT_APP_AAIO_KEY1;

  const orderId = Date.now().toString(36) + Math.random().toString(36).substring(2);

  const signString = `${merchantId}:${price}:${currency}:${Key1}:${orderId}`;
  const sign = CryptoJS.SHA256(signString).toString(CryptoJS.enc.Hex);
  const paymentUrl = `https://aaio.so/merchant/pay?merchant_id=${merchantId}&amount=${price}&currency=${currency}&order_id=${orderId}&sign=${sign}&returnUrl=${encodeURIComponent(
    returnUrl
  )}`;
  window.location.href = paymentUrl;
}

export default redirectToPaymentAaio;
