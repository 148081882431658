import React from "react";
import "../styles/Card.css";

const Card = ({ header, lessons }) => {
    return (
        <div className="cardCont">
            <div className="cardHeader">{header}</div>
            {lessons.map((lesson, index) => (
                <div key={index} className="cardText">
                    <span className="cardLesson">{lesson.title}</span>  {lesson.description}
                </div>
            ))}
        </div>
    );
};

export default Card;