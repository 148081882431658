const handleCryptoPaymentTether = async (price) => {
    const paymentWindow = window.open("", "_blank");
    try {
      const response = await fetch("https://api.nowpayments.io/v1/invoice", {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_SECRET_KEY_USDT,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price_amount: price,
          price_currency: "rub",
          pay_currency: "USDTBSC",
        }),
      });
      const data = await response.json();
      if (data.invoice_url) {
        paymentWindow.location.href = data.invoice_url;
      } else {
        paymentWindow.close();
        alert("Failed to create payment:", data);
      }
    } catch (error) {
      paymentWindow.close();
      alert("Error making API request:", error);
    }
  };


  export default handleCryptoPaymentTether