import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import { ReactComponent as Logo } from "../assets/logo.svg";
import "../styles/Navbar.css";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <RouterLink to="/">
          {" "}
          <Logo className="logo" />
        </RouterLink>

        <div className="navbar-menu">
          <a>Oстались вопросы или проблемы с оплатой?</a>
          <button className="navbar-contact">
            <text class="text">Связь с администратором</text>
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
