import React from "react";
import Navbar from "../components/Navbar";
import '../styles/MainPage.css'
import HeroSection from "../components/HeroSection";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";


function MainPage() {
    return (
        <div className="page">
            <Navbar/>
            <HeroSection/>
            <FAQ/>
            <Footer/>
            <a href="https://aaio.so/" target="_blank" className="aaio">
          <img
            src="https://aaio.so/assets/svg/banners/mini/dark-1.svg"
            title="Aaio - Сервис по приему онлайн платежей"
          />
        </a>
        </div>
    );
}

export default MainPage;
