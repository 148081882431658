import React, { useState } from "react";
import { useParams } from "react-router-dom";
import handleCryptoPaymentTether from "./payments/handleCryptoPaymentTether";

import "../styles/Purchase.css";
import "../styles/MyDropdown.css";
import { ReactComponent as Tether } from "../assets/Tether.svg";
import { ReactComponent as MC } from "../assets/MC.svg";
import { ReactComponent as SEPA } from "../assets/SEPA.svg";
import { ReactComponent as VISA } from "../assets/VISA.svg";
import PaymentForm from "./payments/PaymentForm";
import redirectToPaymentAaio from "./payments/redirectToPaymentAaio";
import redirectToPaymentYookasa from "../components/payments/redirectToPaymentYookasa";

const Purchase = () => {
  let text1, text2, price;
  const { productId } = useParams();
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };
  const handleCryptoPaymentAaio = () => { };
  switch (productId) {

    case "1":
      text1 = "Базовый курс";
      text2 = "(Бот без обратной связи)";
      price = "2900";
      break;
    case "2":
      text1 = "Расширенный курс";
      text2 = "(Бот+поддержка в чате)";
      price = "4900";
      break;
    case "3":
      text1 = "Премиум курс";
      text2 = "(Бот+поддержка в чате+персональная консультация)";
      price = "9900";
      break;
    default:
      text1 = "Not available";
      text2 = "Not available";
      price = null;
  }

  return (
    <div className="purchaseCont">
      <div className="purchse_head">
        <h1>Вы покупаете</h1>
      </div>
      <div className="dropdown-header">
        <div className="dropname">
          <span className="row">
            <span className="dropdown-question1">{text1}</span>
            <span className="dropdown-question2">{text2}</span>
          </span>
          <span className="pricecont">
            <span className="priceP1">Цена:&nbsp;</span>
            <span>{price}&nbsp;руб.</span>
          </span>
        </div>
      </div>
      <div className="purchse_head">
        <h1 className="pay">Выберите способ оплаты</h1>
      </div>

      <div className="payCont">
        <span
          className={isHovered1 ? "svg-hovered" : "svg-normal"}
          onMouseEnter={() => setIsHovered1(true)}
          onMouseLeave={() => setIsHovered1(false)}
        >
          <VISA className="svg svg1" />
        </span>
        <div>
          <span
            className={isHovered2 ? "svg-hovered" : "svg-normal"}
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            // onClick={toggleFormVisibility}
            onClick={() => redirectToPaymentYookasa({ price })}
          >
            <MC className="svg svg2" />
          </span>
          {/* <div className="form-container">
            {isFormVisible && <PaymentForm price={price} />}
          </div> */}
        </div>
        <span
          className={isHovered3 ? "svg-hovered" : "svg-normal"}
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          onClick={() => redirectToPaymentAaio(price)}
        >
          <SEPA className="svg svg3" />
        </span>
        <span
          className={isHovered4 ? "svg-hovered" : "svg-normal"}
          onMouseEnter={() => setIsHovered4(true)}
          onMouseLeave={() => setIsHovered4(false)}
          onClick={() => handleCryptoPaymentTether(price)}
        >
          <Tether className="svg svg4" />
        </span>
      </div>
    </div>
  );
};
export default Purchase;
