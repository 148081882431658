import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Purchase from "../components/Purchase";
import "../styles/PurchasePage.css";
import { useParams } from "react-router-dom";

function PurchasePage() {
  const { productId } = useParams();

  return (
    <div className="page">
      <Navbar />
      
      <Purchase/>
      <Footer/>
    </div>
  );
}

export default PurchasePage;
