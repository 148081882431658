import React from "react";
import MyDropdown from "../components/MyDropdown.js";
import "../styles/FAQ.css";
import { ReactComponent as Drop1 } from '../assets/drop1.svg';
import { ReactComponent as Drop2 } from '../assets/drop2.svg';
import { ReactComponent as Drop3 } from '../assets/drop3.svg';
import { ReactComponent as Drop1_mob } from '../assets/drop1_mob.svg';
import { ReactComponent as Drop2_mob } from '../assets/drop2_mob.svg';
import { ReactComponent as Drop3_mob } from '../assets/drop3_mob.svg';
import AnswerMob1 from "./AnswerMob1.js";
import AnswerMob2 from "./AnswerMob2.js";
import AnswerMob3 from "./AnswerMob3.js";

const FAQ = () => {
  return (
    <div id="faq" className="question-container-align">
      <div className="question-container">
        <MyDropdown
          question1='Базовый курс'
          question2="(Бот без обратной связи)"
          price="2900"
          Pid='1'
          answer=<Drop1 className="dropImg1" />
          answer2 = <Drop1_mob className="dropImg1_mob" />
          answer_mob = <AnswerMob1 />
        />
        <MyDropdown
          question1='Расширенный курс'
          question2="(Бот+поддержка в чате)"
          price="4900"
          price2="6000"
          Pid='2'
          answer=<Drop2 className="dropImg2" />
          answer2=<Drop2_mob className="dropImg2_mob" />
          answer_mob = <AnswerMob2 />


        />
        <MyDropdown
          question1='Премиум курс'
          question2="(Бот+поддержка в чате+персональная консультация)"
          price="9900"
          Pid='3'
          answer=<Drop3 className="dropImg3" />
          answer2=<Drop3_mob className="dropImg3_mob" />
          answer_mob=<AnswerMob3 />

        />

      </div>
    </div>
  );
};

export default FAQ;
